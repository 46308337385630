$use_maison: true;
/* stylelint-disable selector-pseudo-class-no-unknown */

@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/fonts';

.card__modal {
  background-color: $white;
  border-top-left-radius: 12px;
  border-top-right-radius: 16px;
  bottom: 0;
  box-sizing: border-box;
  max-height: 90%;
  min-height: 50%;
  outline: none;
  overflow-y: hidden;
  position: absolute;
  width: 100%;

  &--in-center {
    border-radius: 8px;
    bottom: 50%;
    margin-left: 10%;
    max-height: 60%;
    transform: translateY(50%);
    width: 80%;

    @include breakpoint(desktop) {
      height: auto;
      margin-left: 30%;
      width: 40%;
    }
  }
}

.card__overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 26;
}

.card__body {
  height: 100%;
  width: 100%;
}

.card__closeButton {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
}

.card__sticky-header-close-button {
  margin-right: 16px;
}

.card__sticky-header {
  @include z-index('search');

  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $grey-200;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  position: sticky;
  top: 0;
  width: 100%;
}

.card__sticky-header-title {
  @include font($heading-medium-bold);

  margin-left: 16px;
  transition: all 420ms;
}

// animations
.card__slide {
  :global {
    .ReactModal__Content {
      transform: translateY(100%);
      transform-origin: bottom;
      transition: all 300ms linear;
    }

    .ReactModal__Content--after-open {
      transform: translateY(0%);
    }

    .ReactModal__Content--before-close {
      transform: translateY(100%);
    }
  }
}
