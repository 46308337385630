$use_maison: true;
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/colors';
@import 'styles/spacers';

.rotating-banner__container {
  @include z-index('banner');

  background-color: black;
  min-height: 40px;
  position: relative;
  transition: min-height 300ms ease, background-color 300ms ease;
}

.rotating-banner__container--sticky {
  position: sticky;
  top: 0;
}

.rotating-banner__country-link {
  @include z-index('search');

  color: $white;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);

  &--inline {
    position: relative;
    right: 0;
    top: 0;
  }
}

.rotating-banner__country-flag {
  padding-right: $spacer-xs;
  position: relative;
  top: 2px;
}

.shadow-user-banner {
  background: $red-200;
  color: $red-400;
  padding: 16px;

  &__text {
    @include font($body-small);

    color: $red-400;

    &--bold {
      @include font($body-small-bold);

      color: $red-400;
    }
  }

  &__button {
    @include font($body-small-bold);

    color: $red-400;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
  }
}
